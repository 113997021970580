import React from "react";
import "../styles/form.css";
import Button from "./button";

function Form() {
  return (
    <>
      <div className="form-wrapper">
        <form name="contact" method="POST" data-netlify="true" action="/thank-you">
          <input type="hidden" name="bot-field" />
          <input type="hidden" name="form-name" value="contact" />
          <h2>Contact RunSeanRun today</h2>
          <label htmlFor="name">Name</label>
          <input type="text" name="name" />
          <label htmlFor="email">Email</label>
          <input type="email" name="email" />
          <label htmlFor="message">Message</label>
          <textarea name="message" />
          <Button type="submit">Send Message</Button>
        </form>
      </div>
    </>
  );
}

export default Form;
