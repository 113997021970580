import React from "react"

import { BORDER_RADIUS } from "../styles/constants"
import "../styles/button.css"

const Button = ({ children }) => (
  <button
    style={{
      padding: "1rem 1.2rem",
      color: "black",
      fontWeight: 700,
      background: "white",
      borderRadius: BORDER_RADIUS,
      borderWidth: 0,
      cursor: "pointer",
      marginTop: "10px"
    }}
  >
    {children}
  </button>
)

export default Button
