import React from "react";
import Form from "../components/contact-form";
import Layout from "../components/layout";
import "../styles/contact.css";

const Contact = () => (
  <Layout>
    <div className="section-contact">
      <Form />
    </div>
  </Layout>
);

export default Contact;
