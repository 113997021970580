import React from "react"
import PropTypes from "prop-types"
import headerImage from "../images/header.png"
import Logo from "../images/spf.png"

const Header = () => (
  <div
    style={{
      display: "flex",
      alignItems: "center",
      flexDirection: "column",
      padding: "1rem 1rem",
      backgroundColor: "#2e3550"
    }}>
    <div
      style={{
        backgroundImage: `url(${headerImage})`,
        position: "absolute",
        top: 0,
        zIndex: -5,
        height: "100vh",
        width: "100vw",
        opacity: 0.5,
      }}
    />
<a href="/"><img src={Logo} alt="RUNSEANRUN SNALE LOGO" style={{ marginBottom: 0}}/></a>
    <h1 style={{ textAlign: "center", color: "white" }}>RunSeanRun</h1>
    <h2 style={{ textAlign: "center", color: "white", fontSize: "1.5rem"}}>Dedicated to teaching & supporting you in becoming more <br></br>self-sufficient in the digital landscape.</h2>
  </div>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
